<template>
	<div>
		<div class="flex">
			<div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

				<h1 class="text-2xl font-bold mb-5">Add a department</h1>

				<form @submit.prevent="submit" class="card border border-blue-200 pt-16 pb-10 px-10 md:px-16" key="company">
					
					<template v-if="getFormError(form)">
						<div class="alert alert-red-soft mb-10">
							<span class="alert-icon">!</span>
							<span>{{ getForm }}</span>
						</div>
					</template>

					<form-group
						left-icon="people-circle-outline"
						name="name"
						v-model="form.data.name.value"
						:form="form"
					>
						Name
					</form-group>

					<form-group
						type="textarea"
						left-icon="help-circle-outline"
						name="description"
						v-model="form.data.description.value"
						:form="form"
					>
						Description
					</form-group>

					<!-- <form-group
						type="select"
						:options="form.colors"
						left-icon="color-palette-outline"
						name="color"
						v-model="form.data.color.value"
						:form="form"
					>
						Color Code
					</form-group> -->

					<div class="text-right">
						<button type="submit" class="button bg-blue-500 hover:bg-blue-600 text-white" :disabled="form.loading">
							<span v-if="form.loading">Submitting...</span>
							<span v-else>Next</span>
						</button>
					</div>

				</form>

			</div>
		</div>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				Group registered successfully
			</div>

			<router-link :to="{name: 'dashboard'}" class="button bg-blue-500 hover:bg-blue-600 text-white">
				Go to Dashboard
			</router-link>
			
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal" @close="closeModal(modalKey, $refs.helpModal)">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Adding an group.
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">Here you can create another user with limited or complete groupistrative priviledges.</p>
					<!-- <p class="mb-2">Fill the form below, submit and they will be notified to log in to the system.</p> -->
				</div>
			</div>

			<button
				type="button"
				class="button border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white"
				@click.prevent="$refs.helpModal.close"
			>
				Got it.
			</button>

		</modal>

	</div>
</template>

<script>
	const colors = [
		'red', 'orange', 'yellow', 'green', 'teal', 'blue', 
		'indigo', 'purple', 'pink', 'ash', 'gray'
	].map(color => ({ title: `<span class="badge badge-${color} mr-4">Group</span> <span class="text-base font-bold">${color.fromSlug()}</span>`, value: color }));

	export default {
		data() {
			return {
				company: null,
				checkmark: require('@/assets/checkmark-base.svg'),
				modalKey: 'groups-new-super-help',
				form: this.$options.basicForm([
					'name',
					'description',
					{name: 'color', rules: 'nullable'},
				], { colors })
			}
		},
		mounted() {
			// this.openModal(this.modalKey, this.$refs.helpModal);
			this.form.data.color.value = colors[Math.floor(Math.random() * colors.length)]?.value;
		},
		methods: {
			async getGroups(forced = false) {
				this.loadResource('groups', forced);
			},
			async submit() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.error = false;
				this.form.loading = true;

				await this.$post({
					url: `${this.$baseurl}/companies/groups`,
					data: this.getFormData(),
					headers: this.headers,
					success: () => {
						this.$refs.successModal.open();
						this.form = this.resetForm(this.form);
						this.form.data.color.value = colors[Math.floor(Math.random() * colors.length)]?.value;
						this.getGroups(true);
					},
					error: error => {
						this.form.error = error;

						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});

				this.form.loading = false;
			}
		}
	}
</script>